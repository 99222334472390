






















































































import { defineComponent } from '@vue/composition-api'
import TopBar from '@/components/organisms/o-top-bar.vue'
import { mapActions, mapState } from 'vuex'
import Pagination from '@/components/base/Pagination.vue'
import {
  usePagination,
  PaginationOptions, parseQuery,
} from '@/composables/pagination'
import { QueryParams } from '@/store/api-plateform-utils'
import router from '@/router'
import { NotificationEmail } from '@/api/interfaces/notificationemail'
import { EmailNotificationBrand } from '@/api/enums/emailNotificationBrand'
import deleteButton from '@/components/molecules/m-delete-button.vue'

export default defineComponent({
  setup (props, ctx) {
    const query = {
      ...router.app.$route.query,
    }
    const totalItems = () => {
      return ctx.root.$store.state.notificationEmail.totalItems
    }
    const {
      paginationOptions,
      paginationRequestParams,
      nbPages,
      onSortBy,
      onSortDesc,
      }: PaginationOptions = usePagination(
    totalItems,
    parseQuery(query),
  ) as PaginationOptions

    return {
      paginationOptions,
      paginationRequestParams,
      nbPages,
      onSortBy,
      onSortDesc,
    }
  },
  name: 'NotificationEmailList',
  components: {
    deleteButton,
    TopBar,
    Pagination,
  },
  async created () {
    this.load(this.baseRequest)
  },
  data () {
    return {
      title: this.$t('notification_email.list.headers.title'),
      headers: [
        {
          text: this.$t('notification_email.list.headers.notification_email'),
          align: 'start',
          value: 'emailNotificationTypeDocument',
        },
        {
          text: this.$t('notification_email.list.headers.training_type'),
          align: 'start',
          value: 'trainingType.name',
        },
        {
          text: this.$t('notification_email.list.headers.actions'),
          value: 'actions',
          align: 'center',
          sortable: false,
        },
      ],
      genericEmailNotificationBrand: EmailNotificationBrand.GENERIC,
    }
  },
  computed: {
    ...mapState('notificationEmail', {
      notificationEmailList: 'list',
      totalItems: 'totalItems',
      loading: 'loading',
    }),
    baseRequest (): QueryParams {
      const request: Record<string, unknown> = {
        ...this.paginationRequestParams,
      }
      return request as QueryParams
    },
  },
  watch: {
    paginationOptions: {
      handler () {
        this.loadData()
      },
      deep: true,
    },
  },
  methods: {
    ...mapActions('notificationEmail', {
      load: 'load',
      deleteById: 'deleteById',
    }),
    loadData () {
      this.addParamsToLocation()
      this.load(this.baseRequest)
    },
    addParamsToLocation () {
      let query: any = {
        ...this.paginationOptions,
      }
      query = Object.fromEntries(
        Object.entries(query).filter(([key, value]) => !!value),
      )

      router.replace({ name: 'NotificationEmail List', query: query })
    },
    goToNotificationEmailForm (item: NotificationEmail) {
      if (item.id) {
        this.$router.push({
          name: 'NotificationEmail Edit',
          params: { idNotificationEmail: item.id.toString() },
        })
      }
    },
  },
  beforeRouteLeave (to, from, next) {
    if (to.name === 'NotificationEmail Edit') {
      this.$store.commit('navigation/resetPile')
      this.$store.commit('navigation/addRoute', from)
    }
    next()
  },
})
